<template>
  <b-row>
    <b-col
      :md="8"
      class="mx-auto">
      <h1 class="my-12 text-center">{{ $t("login.title") }}</h1>
      <b-alert v-if="error" :show="true" variant="danger" class="mb-6">{{ $t(`login.${error}`) }}</b-alert>
      <form>
        <b-form-group class="mb-6">
          <vs-input
            v-model="email"
            :label-placeholder=" $t('login.email')"
            icon-pack="material-icons"
            val-icon-pack="material-icons"
            icon="mail_outline"
            size="large"
            icon-no-border
            class="w-full"
            type="email"
            :success="validEmail"
          />
        </b-form-group>
        <b-form-group>
          <vs-input
            v-model="password"
            :label-placeholder="$t('login.password')"
            icon-pack="material-icons"
            val-icon-pack="material-icons"
            icon="lock"
            size="large"
            icon-no-border
            type="password"
            :success="validPassword"
          />
        </b-form-group>
        <div class="float-right mb-2">
          <a
            href="/forgot-password"
            class="text-xs">{{ $t("login.forgot") }}</a>
        </div>
        <vs-button
          class="w-100 mb-12 mt-6 py-3"
          :disabled="loggingIn || gettingUserInfo"
          @click="sessionStart">{{ $t(buttonLeyend) }}</vs-button>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      error: 'user/errorLogin',
      loggingIn: 'user/isLoggingIn',
      gettingUserInfo: 'user/isGettingUserInfo',
    }),
    validEmail() {
      const regEmail = /.+@.+/;
      return this.email === null ? null : regEmail.test(this.email);
    },
    notValidEmail() {
      return this.validEmail !== null ? !this.validEmail : null;
    },
    validPassword() {
      const regPass = /.{6,}/;
      return this.password === null ? null : regPass.test(this.password);
    },
    notValidPassword() {
      return this.validPassword !== null ? !this.validPassword : null;
    },
    buttonLeyend() {
      let leyend = 'login.action';

      switch (true) {
        case this.gettingUserInfo:
          leyend = 'login.getting-user-info-action';
          break;
        case this.loggingIn:
          leyend = 'login.during-action';
          break;
        default:
      }
      return leyend;
    },
  },
  methods: {
    ...mapActions({ doLogin: 'user/login' }),
    sessionStart() {
      if (this.validPassword && this.validEmail) {
        this.doLogin({ email: this.email, password: this.password });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-con-input-label.is-label-placeholder {
  margin-top: 0;
}
</style>
