export default {
  addLocation({ commit }, location) {
    commit('addLocation', location);
    commit('setCenter', location.geometry.location);
  },
  addMarker({ commit }, { lat, lng }) {
    let $ok = false;
    if (!Number.isNaN(Number.parseFloat(lng)) && !Number.isNaN(parseFloat(lat))) {
      commit('addMarker', { position: { lat: Number.parseFloat(lat), lng: Number.parseFloat(lng) } });
      $ok = true;
    }
    return $ok;
  },
  setZoom({ commit }, zoom) {
    commit('setZoom', zoom);
  },
  removeLocation({ commit }, position) {
    commit('removeLocation', position);
  },
  removeMarker({ commit }, position) {
    commit('removeMarker', position);
  },
  addAddress({ commit }, address) {
    commit('addAddress', address);
  },
  removeAddress({ commit }, position) {
    commit('removeAddress', position);
  },
  setRadius({ commit }, radius) {
    commit('setRadius', radius);
  },
  setResidents({ commit }, residents) {
    commit('setResidents', residents);
  },
  setLocationVisible({ commit }, show) {
    commit('setLocationVisible', show);
  },
  setAddressVisible({ commit }, show) {
    commit('setAddressVisible', show);
  },
  setMarkerAddVisible({ commit }, show) {
    commit('setMarkerAddVisible', show);
  },
  setPlaceOptionSelected({ commit }, option) {
    commit('setPlaceOptionSelected', option);
  },
  setLocationBoundSelected({ commit }, index) {
    commit('setLocationBoundSelected', index);
  },
  setCoordinatesLoadModeSelected({ commit }, option) {
    commit('setCoordinatesLoadModeSelected', option);
  },
  loadMarkersFromFile({ commit }, file) {
    commit('setMarkersFromFile', file.markers.map);
    commit('setMarkersFileName', file.name);
  },
  removeMarkersFile({ commit }) {
    commit('setMarkersFromFile', []);
    commit('setMarkersFileName', null);
  },
};
