export default Object.freeze({
  AUTH: {
    CERTIFICATE: 'certificate',
    ACCESS_TOKEN: 'authenticate',
    REFRESH_TOKEN: 'refresh',
  },
  API: {
    USER: {
      INFO: 'user/info',
    },
  },
});
