/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */

export default {
  saveData(state, data) {
    state.info = data;
  },
  saveToken(state, token) {
    state.token = token;
  },
  saveTokenCertificate(state, certificate) {
    state.certificate = certificate;
  },
  showLoginError(state, message) {
    state.error = message;
  },
  removeErrorLogin(state) {
    state.error = null;
  },
  loggingIn(state, loggingIn) {
    state.loggingIn = loggingIn;
  },
  gettingUserInfo(state, gettingUserInfo) {
    state.gettingUserInfo = gettingUserInfo;
  },
};
