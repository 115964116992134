import Base from './Base';

const model = {
  entityId: null,
  accessToken: null,
  refreshToken: null,
};

class Token extends Base {
  constructor(data) {
    super(data, model);
  }

  isValid() {
    const accessTokenIsString = typeof this.accessToken === 'string';
    const refreshTokenIsString = typeof this.refreshToken === 'string';

    // @TODO Reeplace "(2 - 1) === 1" with sign validation
    const accessTokenIsValid = accessTokenIsString ? (2 - 1) === 1 : false;
    const refreshTokenIsValid = refreshTokenIsString ? (2 - 1) === 1 : false;

    return accessTokenIsValid && refreshTokenIsValid;
  }

  get token() {
    return this.accessToken;
  }

  set token(newToken) {
    this.accessToken = newToken;
  }
}

export default Token;
