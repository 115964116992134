/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */

export default {
  addLocation(state, location) {
    state.segmentation.geo.locations.unshift(location);
  },
  removeLocation(state, position) {
    state.segmentation.geo.locations.splice(position, 1);
  },
  addAddress(state, address) {
    state.segmentation.geo.addresses.unshift(address);
  },
  removeAddress(state, position) {
    state.segmentation.geo.addresses.splice(position, 1);
  },
  setRadius(state, radius) {
    state.segmentation.geo.radius = parseInt(radius, 10) || 1;
  },
  setResidents(state, residents) {
    state.segmentation.geo.residents = parseInt(residents, 10) || 3;
  },
  setCenter(state, location) {
    state.segmentation.geo.center = location;
  },
  setZoom(state, zoom) {
    state.segmentation.geo.zoom = zoom;
  },
  setLocationVisible(state, show) {
    state.segmentation.geo.locationVisible = show;
  },
  setMarkerAddVisible(state, show) {
    state.segmentation.geo.markerAddVisible = show;
  },
  setAddressVisible(state, show) {
    state.segmentation.geo.addressVisible = show;
  },
  setCoordinatesLoadModeSelected(state, option) {
    state.segmentation.geo.coordinatesLoadModeSelected = option;
  },
  setPlaceOptionSelected(state, option) {
    state.segmentation.geo.placeOptionSelected = option;
  },
  setLocationBoundSelected(state, index) {
    state.segmentation.geo.locationBoundSelected = index;
  },
  removeMarker(state, position) {
    state.segmentation.geo.markers.splice(position, 1);
  },
  addMarker(state, marker) {
    state.segmentation.geo.markers.push(marker);
  },
  setMarkersFromFile(state, markers) {
    state.segmentation.geo.file.markers = markers;
  },
  setMarkersFileName(state, name) {
    state.segmentation.geo.file.name = name;
  },
};
