import dayjs from 'dayjs';

const baseModel = {
  createdAt: null,
  createdBy: null,
  modifiedAt: null,
  modifiedBy: null,
  deletedAt: null,
  deletedBy: null,
};
class Base {
  constructor(props, model = false, addAuditFields = false) {
    if (model) {
      Object.assign(this, model, this.filterProps(props || {}, Object.keys(model)));
    } else {
      Object.assign(this, props || {});
    }
    if (addAuditFields) {
      this.addAuditFields(props || {});
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get auditFields() {
    return baseModel;
  }

  // eslint-disable-next-line class-methods-use-this
  get idField() {
    return null;
  }

  get id() {
    return this.idField ? this[this.idField] : null;
  }

  set id(id) {
    if (this.idField) {
      this[this.idField] = id;
    }
  }

  get lastModification() {
    const date = this.modifiedAt ? dayjs(this.modifiedAt) : false;
    return date;
  }

  addAuditFields(props) {
    Object.assign(this, baseModel, this.filterProps(props || {}, Object.keys(baseModel)));
    this.initializeFieldDate(['createdAt', 'modifiedAt', 'deletedAt']);
  }

  // eslint-disable-next-line class-methods-use-this
  filterProps(obj, keys) {
    return keys
      .map((k) => (k in obj
        ? {
          [k]: obj[k],
        }
        : {}))
      .reduce((res, o) => Object.assign(res, o), {});
  }

  initializeArrayField(field) {
    const fieldS = String(field);
    if (!(this[fieldS] instanceof Array)) {
      this[fieldS] = typeof this[fieldS] !== typeof undefined && this[fieldS] !== null ? [this[fieldS]] : [];
    }
  }

  initializeField(fields, fn) {
    fields.forEach(fn, this);
  }

  initializeArrayFields(field) {
    this.initializeField(field, this.initializeArrayField);
  }

  convertToNumber(fieldOrFields) {
    const fields = Array.isArray(fieldOrFields) ? fieldOrFields : [fieldOrFields];
    fields.forEach((field) => {
      if (typeof this[field] !== typeof undefined && this[field] !== null) {
        if (Array.isArray(this[field])) {
          this[field].map((v, ix, arr) => (arr[ix] === parseInt(arr[ix], 10) ? parseInt(arr[ix], 10) : 0));
        } else {
          this[field] = parseInt(this[field], 10) ? parseInt(this[field], 10) : 0;
        }
      }
    });
  }

  initializeDateField(field) {
    if (this[field]) {
      const fecha = dayjs(this[field]);
      this[field] = fecha.isValid() ? fecha : null;
    }
  }

  initializeDateFields(field) {
    this.initializeField(field, this.initializeDateFields);
  }

  setIfOneOf(field, value, oneof, optional) {
    if ((value === null && optional) || oneof.filter((v) => (v === value)).length) {
      this[field] = value;
    } else {
      throw new Error(`${value} is invalid, it shoud be one of: "${oneof.join('", "')}"`);
    }
  }
}

export default Base;
