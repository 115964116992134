import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// const fnRedirectToDashboard = (to) => {
//   const url = `https://dashboard.wortise.com${to.path}`;
//   console.info('Es redirect', url, to);
//   // window.location.href = url
//   return to.path;
// };

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: '',
    component: () => import('@/layouts/Main.vue'),
    children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
      {
        path: '/',
        name: 'campaigns',
        component: () => import('@/views/CampaignsCreation.vue'),
      },
      // { name: 'home', path: '/', redirect: fnRedirectToDashboard },
      // { name: 'apps', path: '/assets', redirect: fnRedirectToDashboard },
      // { name: 'settings-payments', path: '/payments', redirect: fnRedirectToDashboard },
      {
        path: '*',
        name: 'error404',
        component: () => import('@/views/Error404.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
