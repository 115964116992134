import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';

import '@/plugins/axios';
import '@/plugins/bootstrap-vue';
import '@/plugins/dayjs';
import '@/plugins/gmap-vue';
import '@/plugins/vue2-hammer';
import '@/plugins/vue-svg-inline-plugin';
import '@/plugins/vuesax';
import '@/plugins/vuexy';

// import '@/registerServiceWorker';
import '@/directives';

// CSS
import '@/assets/scss/main.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
