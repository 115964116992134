export default {
  getInfo(state) {
    return { ...state.info };
  },
  /**
   * @param {object} state
   *
   * @todo Validar la firma del token
   */
  getTokenPayload(state) {
    let result = null;
    if (state.token && state.token.isValid()) {
      const tokenParts = state.token.accessToken.split('.');
      result = JSON.parse(atob(tokenParts[1]));
    }
    return result;
  },

  getAccessToken(state) {
    return state.token.accessToken;
  },

  getRefreshToken(state) {
    return state.token.refreshToken;
  },

  isLoggedIn(state) {
    return typeof state.info === 'object';
  },

  isLoggingIn(state) {
    return state.loggingIn;
  },

  isGettingUserInfo(state) {
    return state.gettingUserInfo;
  },

  hasToLogin(state) {
    return typeof state.info !== 'object';
  },

  errorLogin(state) {
    return state.error;
  },
};
