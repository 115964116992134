import Base from './Base';

const model = {
  email: null,
  password: null,
  service: `${process.env.VUE_APP_AUTH_SERVICEID}`,
};

class TokenSignIn extends Base {
  constructor(data) {
    super(data, model);
  }
}

export default TokenSignIn;
