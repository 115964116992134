export default {
  getCenter(state) {
    return state.segmentation.geo.center;
  },
  getZoom(state) {
    return state.segmentation.geo.zoom;
  },
  getLocations(state) {
    return [...state.segmentation.geo.locations];
  },
  getAddresses(state) {
    return [...state.segmentation.geo.addresses];
  },
  getRadius(state) {
    return state.segmentation.geo.radius;
  },
  getResidents(state) {
    return state.segmentation.geo.residents;
  },
  getLocationVisible(state) {
    return state.segmentation.geo.locationVisible;
  },
  getAddressVisible(state) {
    return state.segmentation.geo.addressVisible;
  },
  getPlaceOptionSelected(state) {
    return state.segmentation.geo.placeOptionSelected;
  },
  getLocationBoundSelected(state) {
    return state.segmentation.geo.locationBoundSelected;
  },
  getCoordinatesLoadModeSelected(state) {
    return state.segmentation.geo.coordinatesLoadModeSelected;
  },
  getMarkerAddVisible(state) {
    return state.segmentation.geo.markerAddVisible;
  },
  getMarkers(state) {
    return [...state.segmentation.geo.markers];
  },
  getMarkersFromFile(state) {
    return [...state.segmentation.geo.file.markers];
  },
  getMarkerFileName(state) {
    return state.segmentation.geo.file.name;
  },
};
