/*= ========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

// Modules
import user from './modules/user';
import audience from './modules/audience';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'wortise-revolution',
  storage: window.localStorage,
  modules: ['user', 'audience'],
  // reducer: state => ({ products: state.products }),
});

const store = new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user,
    audience,
  },
  plugins: [vuexPersist.plugin],
});

export default store;
