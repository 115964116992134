import Vue from 'vue';
import axios from 'axios';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

const configAuth = {
  baseURL: process.env.VUE_APP_AUTH_ENDPOINT,
  timeout: 20 * 1000, // Timeout
};

const configAPI = {
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 20 * 1000, // Timeout
};

const api = axios.create(configAPI);
const auth = axios.create(configAuth);

api.defaults.headers.post['Content-Type'] = 'application/json';
auth.defaults.headers.post['Content-Type'] = 'application/json';

Plugin.install = function axiosInstall($vue /* , options */) {
  // eslint-disable-next-line no-param-reassign
  window.$api = api;
  window.$auth = auth;
  Object.defineProperties($vue.prototype, {
    $api: {
      get() {
        return api;
      },
    },
    $auth: {
      get() {
        return auth;
      },
    },
  });
};

Vue.use(Plugin);

export { auth, api };
