import Token from '@/models/Token';
import TokenSignIn from '@/models/TokenSignIn';
import URL from '@/constants/urls';

export default {
  removeLoginErrors({ commit }) {
    commit('removeErrorLogin');
  },
  showLoginError({ commit }, message) {
    commit('showLoginError', message);
  },
  activateLoggingIn({ commit }) {
    commit('loggingIn', true);
  },
  deactivateLoggingIn({ commit }) {
    commit('loggingIn', false);
    commit('gettingUserInfo', false);
  },
  activateGettingUserInfo({ commit }) {
    commit('gettingUserInfo', true);
  },
  deactivateGettingUserInfo({ commit }) {
    commit('gettingUserInfo', false);
  },
  saveToken({ commit }, token) {
    commit('saveToken', token);
  },
  recoverData({ commit, getters, dispatch }) {
    const token = getters.getTokenPayload;
    const entityId = token.sub;
    const fnOk = (resp) => {
      commit('saveData', resp.data);
      dispatch('deactivateLoggingIn');
    };
    const fnError = (error) => {
      if (error) { console.error(error); }
      dispatch('showLoginError', 'login-error');
      dispatch('deactivateLoggingIn');
    };

    if (!entityId) { fnError(false); }
    dispatch('activateGettingUserInfo');
    return window.$api
      .get(URL.API.USER.INFO)
      .then(fnOk)
      .catch(fnError);
  },
  saveCertificate({ commit }) {
    window.$auth.get(URL.AUTH.CERTIFICATE)
      .then((resp) => commit('saveCertificate', resp.data))
      .catch((error) => {
        commit('saveCertificate', null);
        console.error('CertificateError', error);
      });
  },
  login({ getters, dispatch }, payload) {
    if (getters.isLoggedIn) {
      console.info('Already signin in');
      return null;
    }
    const fnOk = (resp) => {
      const token = new Token(resp.data.data);
      if (token.isValid()) {
        dispatch('saveToken', token);
        dispatch('recoverData');
        dispatch('saveCertificate');
      } else {
        dispatch('showLoginError', 'login-error-token');
        dispatch('deactivateLoggingIn');
      }
    };

    const fnError = (error) => {
      const status = error.response ? parseInt(error.response.status, 10) : null;
      console.error(error);
      dispatch('showLoginError', status === 401 ? 'login-invalid' : 'login-error');
      dispatch('deactivateLoggingIn');
    };

    dispatch('activateLoggingIn');
    dispatch('removeLoginErrors');
    return window.$auth
      .post(URL.AUTH.ACCESS_TOKEN, new TokenSignIn(payload))
      .then(fnOk)
      .catch(fnError);
  },
};
