export default () => ({
  segmentation: {
    geo: {
      locations: [],
      addresses: [],
      locationVisible: true,
      addressVisible: true,
      markerAddVisible: true,
      placeOptionSelected: '-',
      coordinatesLoadModeSelected: 'manual',
      categories: {
        supermarket: false,
        farmacy: false,
        gas: false,
        bank: false,
        mail: false,
        hotel: false,
        hospital: false,
      },
      radius: 100,
      residents: 3,
      locationBoundSelected: 0,
      center: { lat: -16.4254594, lng: -62.1932123 },
      zoom: 4,
      // Coordinates (GEO)
      markers: [],
      file: {
        markers: [],
        name: null,
      },
    },
  },
});
